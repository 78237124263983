import {
  BuyerDetailInterface,
  BuyerInterface,
} from '@features/buyers/domain/entities/buyers.interface';
import { Observable } from 'rxjs';

export abstract class BuyersApiPort {
  abstract getBuyers(): Observable<BuyerDetailInterface[]>;

  abstract getArchivedBuyers(): Observable<BuyerInterface[]>;
}
