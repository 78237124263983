import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class IdGeneratoreService {
  generateId() {
    return uuidv4();
  }

  generateShortenedId() {
    return uuidv4().slice(0, 6);
  }
}
