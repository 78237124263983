import { ActiviteInterface } from '@src/shared/activites/interfaces/activities.interface';

export const activitesObject: ActiviteInterface[] = [
  {
    id: 1,
    name: 'Bar',
    iconsPath: './assets/activite-icons/bar.svg',
    color: '#FB923C',
  },
  {
    id: 2,
    name: 'Restaurant',
    iconsPath: './assets/activite-icons/restaurant.svg',
    color: '#65A30B',
  },
  {
    id: 3,
    name: 'Snack - Sandwicherie',
    iconsPath: './assets/activite-icons/burger.svg',
    color: '#F59E0B',
  },
  {
    id: 4,
    name: 'Pubs - Discotheques',
    iconsPath: './assets/activite-icons/champagne.svg',
    color: '#C026D3',
  },
  {
    id: 5,
    name: 'Hotels, Campings, Gites',
    iconsPath: './assets/activite-icons/hotel.svg',
    color: '#CA8A04',
  },
  {
    id: 6,
    name: 'Pret à Porter, Chaussures',
    iconsPath: './assets/activite-icons/shopping.svg',
    color: '#0D9488',
  },
  {
    id: 7,
    name: 'Boulangerie, Terminal cuisson',
    iconsPath: './assets/activite-icons/bread.svg',
    color: '#FBBF24',
  },
  {
    id: 8,
    name: 'Commerces Alimentaires',
    iconsPath: 'assets/activite-icons/cart.svg',
    color: '#F97316',
  },
  {
    id: 9,
    name: 'Presse, Librairie',
    iconsPath: './assets/activite-icons/news.svg',
    color: '#38BDF8',
  },
  {
    id: 10,
    name: 'Esthétique, Coiffure',
    iconsPath: 'assets/activite-icons/barber.svg',
    color: '#7C3AED',
  },
  {
    id: 11,
    name: 'Pharmacie, Paramédical',
    iconsPath: './assets/activite-icons/cross.svg',
    color: '#22C55E',
  },
  {
    id: 12,
    name: 'Tabac, Tabac-Presse, Loto',
    iconsPath: './assets/activite-icons/diamond.svg',
    color: '#F43F5E',
  },
  {
    id: 13,
    name: 'Fleurs Déco Cadeaux',
    iconsPath: 'assets/activite-icons/flower.svg',
    color: '#F472B6',
  },
  {
    id: 14,
    name: 'Entreprises - PME/PMI',
    iconsPath: 'assets/activite-icons/bag.svg',
    color: '#A16207',
  },
  {
    id: 15,
    name: 'Locaux Commerciaux',
    iconsPath: 'assets/activite-icons/store.svg',
    color: '#854D0E',
  },
  {
    id: 16,
    name: 'Autres Commerces, Services',
    iconsPath: 'assets/activite-icons/other.svg',
    color: '#06B6D4',
  },
  {
    id: 17,
    name: 'Bureaux',
    iconsPath: 'assets/activite-icons/corporate.svg',
    color: '#059669',
  },
  {
    id: 18,
    name: "Locaux d'Activités, Entrepots",
    iconsPath: 'assets/activite-icons/warehouse.svg',
    color: '#A1A1AA',
  },
  {
    id: 19,
    name: 'Murs Commerciaux',
    iconsPath: 'assets/activite-icons/wall.svg',
    color: '#92400E',
  },
  {
    id: 20,
    name: 'Terrains',
    iconsPath: 'assets/activite-icons/landscape.svg',
    color: '#84CC16',
  },
];
