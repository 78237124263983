import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActiviteIconsComponent } from 'src/libs/activite-icons/activite-icons.component';

@NgModule({
  declarations: [ActiviteIconsComponent],
  imports: [CommonModule],
  exports: [ActiviteIconsComponent],
})
export class ActiviteIconsModule {}
