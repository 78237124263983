<div
  [ngClass]="{
    'input-focused': checkIfValueExist() || isInputFocused,
    'input-invalid':
      inputControl && inputControl.invalid && inputControl.touched,
    desktop: forDesktop
  }"
  class="input_slide-container {{ classes }}">
  <input
    (blur)="onFocus(false)"
    (click)="onTouched()"
    (focus)="onFocus(true)"
    (input)="updateValue($event)"
    (keydown.enter)="update.emit()"
    [(ngModel)]="innerValue"
    [id]="inputId"
    [readOnly]="readonly"
    [type]="type"
    autocomplete="on" />
  <label [class.required]="required" [for]="inputId" class="placeholder"
    >{{ placeholder }}
  </label>
  <div class="symbol">
    {{ iconUnit }}
  </div>
</div>
<div
  *ngIf="inputControl && inputControl.invalid && inputControl.touched"
  class="error-message">
  {{
    ErrorMessageValidator.getErrorMessageValidator(
      inputControl.errors,
      placeholder
    )
  }}
</div>
