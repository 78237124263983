import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectComponent } from '@libs/select/select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [NgClass, NgStyle, NgForOf, NgIf, FormsModule],
  exports: [NgClass, SelectComponent],
})
export class SelectModule {}
