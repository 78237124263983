import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSlideComponent } from '@libs/input-slide/input-slide.component';

@NgModule({
  declarations: [InputSlideComponent],
  imports: [CommonModule, FormsModule],
  exports: [InputSlideComponent],
})
export class InputSlideModule {}
