import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const ErrorMessageValidator = {
  getErrorMessageValidator(
    error: ValidationErrors | null | undefined,
    inputName?: string
  ): string {
    inputName = inputName?.toLowerCase();
    if (!error) {
      return '';
    }
    if (error['required']) {
      return `Le champ ${inputName} est requis.`;
    }
    if (error['minMaxLengthEach']) {
      return error['minMaxLengthEach'].message;
    }
    if (error['minlength']) {
      return `Le champ ${inputName} doit contenir ${error['minlength'].requiredLength - error['minlength'].actualLength} caractères supplémentaire.`;
    }
    if (error['maxlength']) {
      return `Le champ ${inputName} doit contenir ${error['maxlength'].actualLength - error['maxlength'].requiredLength} caractères de moins.`;
    }
    if (error['min']) {
      return `Le champ ${inputName} doit être supérieur à ${error['min']['min']}.`;
    }
    if (error['max']) {
      return `Le champ ${inputName} doit être inférieur à ${error['max']['max']}.`;
    }
    if (error['notUnique']) {
      return `Le champ ${inputName} doit être unique.`;
    }
    return `Le champ ${inputName} est invalide.`;
  },
};

export function internationalPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || control.value.trim() === '') {
      return null;
    }
    const phoneNumber = parsePhoneNumberFromString(control.value, 'FR'); // 'FR' est le pays par défaut
    if (!phoneNumber) {
      return { invalidPhone: { value: control.value } };
    }
    const valid = phoneNumber.isValid();
    return valid ? null : { invalidPhone: { value: control.value } };
  };
}
