import { Component, Input, OnInit } from '@angular/core';
import { activitesObject } from '@src/shared/activites/objects/activities.object';

@Component({
  selector: 'app-activite-icons',
  templateUrl: './activite-icons.component.html',
  styleUrls: ['./activite-icons.component.scss'],
})
export class ActiviteIconsComponent implements OnInit {
  @Input() activiteId!: number;
  @Input() size: 'small' | 'medium' = 'medium';

  currentActivite!: (typeof activitesObject)[0] | undefined;

  get ActiviteClasses(): string {
    return `circle ${this.size}`;
  }

  ngOnInit(): void {
    this.currentActivite = activitesObject.find(a => a.id === this.activiteId);
  }
}
