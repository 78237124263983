import { Injectable } from '@angular/core';
import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';
import { BuyersApiPort } from '@features/buyers/domain/ports/buyers-api.port';
import { LoadSilenceBuyers } from '@features/buyers/domain/redux/actions/buyers.action';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

export class BuyersStateModel {
  buyers!: BuyerDetailInterface[];
}

export const defaultBuyersState: BuyersStateModel = {
  buyers: [],
};

@State<BuyersStateModel>({
  name: 'buyers',
  defaults: defaultBuyersState,
})
@Injectable()
export class BuyersState implements NgxsOnInit {
  constructor(public buyersService: BuyersApiPort) {}

  ngxsOnInit(ctx: StateContext<BuyersStateModel>): void {
    ctx.dispatch(new LoadSilenceBuyers());
  }

  @Action(LoadSilenceBuyers)
  async loadSilenceBuyers(ctx: StateContext<BuyersStateModel>): Promise<void> {
    try {
      const buyers = await lastValueFrom(this.buyersService.getBuyers());
      ctx.patchState({
        buyers: buyers,
      });
    } catch (error) {
      console.error('Erreur lors du chargement des buyers', error);
    }
  }
}
