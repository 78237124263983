import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';
import {
  BuyersState,
  BuyersStateModel,
} from '@features/buyers/domain/redux/state/buyers.state';
import { createSelector, Selector } from '@ngxs/store';

export class BuyersSelectors {
  @Selector([BuyersState])
  static buyers(state: BuyersStateModel): BuyerDetailInterface[] {
    return state.buyers;
  }

  static searchBuyers(searchTerm: string) {
    return createSelector(
      [BuyersState],
      (state: BuyersStateModel): BuyerDetailInterface[] => {
        const filteredBuyers = state.buyers.filter(buyer =>
          this.isBuyerMatchingSearchTerm(buyer, searchTerm)
        );
        return this.removeDuplicateBuyersById(filteredBuyers);
      }
    );
  }

  private static isBuyerMatchingSearchTerm(
    buyer: BuyerDetailInterface,
    searchTerm: string
  ): boolean {
    const term = searchTerm.toLowerCase();
    return (
      buyer.nom?.toLowerCase().includes(term) ||
      buyer.prenom?.toLowerCase().includes(term) ||
      buyer.email?.toLowerCase().includes(term) ||
      buyer.mobile?.toLowerCase().includes(term)
    );
  }

  private static removeDuplicateBuyersById(
    buyers: BuyerDetailInterface[]
  ): BuyerDetailInterface[] {
    const uniqueBuyersMap = buyers.reduce(
      (map, buyer) => map.set(buyer.id, buyer),
      new Map<number, BuyerDetailInterface>()
    );
    return Array.from(uniqueBuyersMap.values());
  }
}
