<div
  (click)="toggleDropdown()"
  (keydown.enter)="toggleDropdown()"
  [class.disabled]="isDisabled"
  [ngClass]="classes"
  class="custom-select"
  tabindex="0">
  <div [ngClass]="fontSize === 'small' ? 'fz-14' : null" class="selected-item">
    {{
      selectedValue && selectedValue.id !== 0
        ? selectedValue?.name
        : placeholder
    }}
  </div>
  <div *ngIf="showDropdown" class="dropdown">
    <div
      (click)="selectItem(item, $event)"
      (keydown.enter)="selectItem(item, $event)"
      *ngFor="let item of items"
      [class.selected]="item.id === innerValue"
      [ngClass]="fontSize === 'small' ? 'fz-14' : null"
      tabindex="0">
      {{ item.name }}
    </div>
  </div>
</div>
