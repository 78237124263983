import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from '@libs/select/select-options';

type BorderStyle = 'secondary';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  public innerValue: number | string = '';
  public isDisabled = false;
  @Input() items: SelectItem[] = [
    { name: 'Option 1', id: '1', value: 'value1' },
    { name: 'Option 2', id: '2', value: 'value2' },
    { name: 'Option 3', id: '3', value: 'value3' },
  ];
  public selectedValue?: SelectItem;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' | 'fit' =
    'medium';
  @Input() rounded: 'true' | 'false' = 'true';
  @Input() borderColor: BorderStyle = 'secondary';
  @Input() invalid: true | false = false;
  @Input() placeholder!: string;
  @Input() isWarning = false;
  @Input() fontSize: 'small' | 'medium' = 'medium';
  @Output() changed = new EventEmitter<SelectItem>();
  public showDropdown = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onTouched: any;

  constructor(
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  public get classes(): string[] {
    const classes = ['ebb-select', this.size, this.borderColor];
    if (this.invalid) {
      classes.push('invalid');
    }
    if (this.isWarning) {
      classes.push('warning');
    }
    if (this.showDropdown) {
      classes.push('rotate-svg', 'dropdown-open', 'rounded-top');
    } else {
      this.rounded && classes.push('rounded');
    }
    return classes;
  }

  @HostListener('document:click', ['$event']) clickout(event: Event) {
    // Close dropdown only if it's opened and if click was outside of this component
    if (this.showDropdown && !this.elRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
      this.cdr.detectChanges();
    }
  }

  public writeValue(value: string) {
    this.innerValue = value;
    this.selectedValue = this.items.find(i => i.id === value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  public selectItem(item: SelectItem, event: Event) {
    this.selectedValue = item;
    this.innerValue = item.id;
    this.onChange(this.innerValue);
    this.showDropdown = false;
    event.stopPropagation();
    this.changed.emit(this.selectedValue);
  }

  public toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.cdr.detectChanges();
  }
}
