import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { InputType } from '@libs/input/input.types';
import { IdGeneratoreService } from '@src/shared/id-generator/id-generatore.service';
import {
  InputSlideType,
  InputSlideWidthType,
} from '@libs/input-slide/input-slide.type';
import { ErrorMessageValidator } from '@src/shared/utils/error-message-validator.contants';

@Component({
  selector: 'app-input-slide',
  templateUrl: './input-slide.component.html',
  styleUrl: './input-slide.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputSlideComponent),
    },
  ],
})
export class InputSlideComponent implements ControlValueAccessor, OnInit {
  @Input() inputControl!: FormControl;
  @Input() invalid: true | false = false;
  @Output() update = new EventEmitter<void>();
  @Input() type: InputType = 'text';
  @Input({ required: true }) inputType: InputSlideType = 'price';
  @Input() width: InputSlideWidthType = 'medium';
  @Input() placeholder!: string;
  @Input() readonly = false;
  @Input() forDesktop = false;
  @Input() icon: 'left' | 'right' | 'none' = 'none';
  @Input() required = false;
  public innerValue!: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isDisabled = false;
  public iconUnit!: string;
  public inputId = this.idGeneratorService.generateShortenedId();
  isInputFocused = false;
  protected readonly ErrorMessageValidator = ErrorMessageValidator;

  constructor(
    private cdr: ChangeDetectorRef,
    private idGeneratorService: IdGeneratoreService
  ) {}

  public get classes(): string[] {
    const classes: string[] = [this.width + ' ' + this.icon];
    return classes;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void = () => {};

  onTouched: () => void = () => {};

  public writeValue(value: string) {
    this.innerValue = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {
    if (this.inputType === 'price') {
      this.iconUnit = '€';
    } else if (this.inputType === 'surface') {
      this.iconUnit = 'm²';
    } else {
      this.iconUnit = '';
    }
  }

  setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  public updateValue(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    const parsedValue = parseFloat(inputValue);
    if (this.type === 'number' && !isNaN(parsedValue)) {
      this.innerValue = parsedValue;
    } else if (this.type === 'number') {
      this.innerValue = inputValue;
    }
    this.onChange(this.innerValue);
  }

  onFocus(b: boolean) {
    this.isInputFocused = b;
    this.cdr.detectChanges();
  }

  checkIfValueExist() {
    return !(
      this.innerValue === null ||
      this.innerValue === undefined ||
      this.innerValue === '' ||
      this.innerValue === 0 ||
      this.innerValue === Infinity
    );
  }
}
